<template>
	<div class="AdditionalList">
		<w-navTab titleText="补卡记录"></w-navTab>
		<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
		<div class="container" v-for="item in list">
			<div class="container-item">
				<div class="item-title">审核状态</div>
				<div class="item-text" v-if="item.status == 1">待审核</div>
				<div class="item-text" v-if="item.status == 2">审核通过</div>
				<div class="item-text" v-if="item.status == 3">审核未通过</div>
			</div>
			<div class="container-item">
				<div class="item-title">活动名称</div>
				<div class="item-text">{{item.title}}</div>
			</div>
			<div class="container-item">
				<div class="item-title">补打卡时间</div>
				<div class="item-text">{{item.register_time}}</div>
			</div>
			<div class="container-item">
				<div class="item-title">一起执勤人姓名</div>
				<div class="item-text">{{item.verify_name}}</div>
			</div>
			<div class="container-item">
				<div class="item-title">一起执勤人电话</div>
				<div class="item-text">{{item.verify_mobile}}</div>
			</div>
			
			<div class="container-footer-text">
				{{item.apply_reason}}
			</div>
			<div class="container-footer-list" v-for="vo in item.apply_photo">
				<img :src="vo" alt="">
			</div>
			<template v-if="item.fail_reason">
				<div class="container-footer-title">
					审核未通过原因
				</div>
				<div class="container-footer-title-text">
					{{item.fail_reason}}
				</div>
			</template>
		</div>
		</VantList>
	</div>
</template>

<script>
	import Additional from '@/api/additional';
	import VantList from '@/components/VantList';
	import ValidatorVendor from '@/vendor/validator';
	export default {
		name: 'AdditionalList',
		data() {
			return {
				list: [],
				page: 1,
				limit: 10,
				loading: true,
				finished: false,
				isEmpty: false,
			}
		},
		created() {
			this.onLoad()
		},
		methods: {
			onLoad(){
				this.loading = true;
				
				let params = {
					page: this.page,
					limit: this.limit
				};
				Additional.additionalRecords(params)
					.then(result => {
						this.page++;
						this.list = this.list.concat(result.data.list);
						this.loading = false;
						this.loadingPage = false;
						if (this.list.length <= 0) {
							this.isEmpty = true;
						}
				
						if (this.list.length >= result.data.total) {
							this.finished = true;
						}
					}, error => {
						VantVendor.Toast.clear();
					})
			}
		},
		components: {
			VantList
		}
	}
</script>

<style scoped lang="less">
	.AdditionalList {
		;

		.container {
			border-radius: 6px;
			background-color: #fff;
			margin: 0 10px;
			margin-top: 10px;
			
			.container-item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10px 0;
				margin: 0 10px;
				border-bottom: 1px solid #F5F6F8;

				.item-title {
					font-size: 16px;
					line-height: 26px;
					color: #777;
				}

				.item-text {
					font-size: 16px;
					line-height: 26px;
					color: #222;
				}
			}

			.container-footer-text {
				font-size: 16px;
				line-height: 26px;
				color: #777;
				padding: 10px;
			}

			.container-footer-list {
				display: flex;
				align-items: center;
				padding: 6px 4.5px 10px;
				border-radius: 6px;
				box-sizing: border-box;

				img {
					width: 75px;
					height: 75px;
					margin: 0 5.5px;
					border-radius: 6px;
					object-fit: cover;
				}
			}
			.container-footer-title{
				font-size: 16px;
				line-height: 26px;
				color: #777;
				padding-left: 10px;
			}
			.container-footer-title-text{
				font-size: 16px;
				line-height: 26px;
				color: #777;
				padding: 10px;
			}
		}
	}
</style>
